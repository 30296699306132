<template>
  <section id="login-page">
    <auth-forms-wrapper width="392px" card-classes="px-0">
      <template #form>
        <v-progress-linear color="#05b6fc" indeterminate rounded height="6" />
        <div class="linear-progress-text">Authenticating</div>
        <form id="saml_form" method="post" :action="saml_acs_url">
          <input type="hidden" name="SAMLResponse" :value="saml_response" />
        </form>
      </template>
    </auth-forms-wrapper>
  </section>
</template>
<script>
import { defer } from "@/utils";
import { mapActions, mapGetters } from "vuex";
import { getSAMLToken } from "@/services/auth";

import AuthFormsWrapper from "@/components/shared/AuthFormsWrapper.vue";

/**
 * Login view
 */
export default {
  name: "GalaxyLogin",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    AuthFormsWrapper,
  },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      saml_response: "",
      saml_acs_url: "",
    };
  },

  mounted() {
    this.getSAMLToken();
  },

  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      getToken: "auth/token",
      isLoggedIn: "auth/isLoggedIn",
    }),
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      fetchRealtimeToken: "auth/fetchRealtimeToken",
    }),
    /**
     * Redirects user to login page
     */
    redirectToLogin() {
      if (this.$route.path !== "/login") {
        this.$router.push({ path: "/login" });
      }
    },
    /**
     * Assertes Galaxy salesforce login process
     */
    async getSAMLToken() {
      try {
        if (!this.isLoggedIn) return this.redirectToLogin();

        await this.fetchRealtimeToken();
        const { data } = await getSAMLToken({
          params: {
            id_token: this.getToken,
          },
        });

        this.saml_response = data.saml_response;
        this.saml_acs_url = data.saml_acs_url;
        defer(() => document.getElementById("saml_form").submit(), 100);
      } catch {
        this.redirectToLogin();
      }
    },
  },
};
</script>
<style>
.auth-form__wrapper .v-card__text {
  margin-top: 0.5em !important;
}
</style>
